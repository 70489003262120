export const wtm_embed = (options) => {

  let defaultOptions = {
    zIndex: 1000
  };

  let loadingIcon = createLoading();
  if(options['eles'] && options['eles'].length > 0) {
    options['eles'].forEach( (e) => {
      addEvent(e, 'click', openWindow)
    });
  } else if(options['ele']) {
    addEvent(options['ele'], 'click', openWindow);
  } else {
    return;
  }

  function addEvent(e, action, func) {
    let eles = document.querySelectorAll(e)
    eles = [].slice.call(eles);
    eles.forEach((ele) => {
      if(ele.addEventListener) {
        ele.addEventListener(action, func);
      } else if(ele.attachEvent) {
        ele.attachEvent(`on${action}`, func);
      } else {
        ele['on' + action] = func;
      }
    })
  }
  
  function openWindow(e) {
    e.preventDefault();
    let iframe = document.createElement('iframe');
    let token = options['listing'];
    if(!token) {
      token = e.target.dataset.token;
    }
    iframe.id = `wtm-${options['key']}-${token}`;
    iframe.src = `https://${options['key']}.weteachme.com/external-booking-forms/${token}`;
    iframe.setAttribute('allowpaymentrequest', true);
    iframe.setAttribute('allowfullscreen', true);
    iframe.classList.add('wtm-booking-form-iframe');
    if(window.innerWidth > 768) {
      iframe.style.width = '95%';
      iframe.style.height = '95%';
      iframe.style.maxWidth = '1240px';
      iframe.style.maxHeight = '755px'
      iframe.style.top = '50%';
      iframe.style.left = '50%';
      iframe.style.transform =  "translate(-50%, -75%)";
      iframe.style.webkitTransform =  "translate(-50%, -75%)";
      iframe.style.borderRadius = "4px";
    } else {
      iframe.style.width = '100%';
      iframe.style.height = '100%';
      iframe.style.top = '0';
      iframe.style.left = 'auto';
      iframe.style.bottom = '0';
      iframe.style.right = '-100%';
      iframe.style.borderRadius = "0";
      iframe.style.transform =  "none";
      iframe.style.webkitTransform =  "none";
    }
    iframe.style.transition = "all 0.3s ease-out";
    
    iframe.style.position = 'fixed';
    if(options['zIndex']) {
      iframe.style.zIndex = options['zIndex'];
    } else {
      iframe.style.zIndex = defaultOptions['zIndex'];
    }
    iframe.style.border = 0;
    let dropback = createBackdrop();
    let loading = createLoading();
    document.body.appendChild(iframe);
    document.body.appendChild(loading);
    document.body.appendChild(dropback);
    findModal(iframe, dropback, loading);
    //resizeWindow(iframe);
  }

  function createBackdrop() {
    let dropback = document.createElement('div');
    dropback.style.position = 'fixed';
    dropback.style.top = 0;
    dropback.style.right = 0;
    dropback.style.left = 0;
    dropback.style.bottom = 0;
    if(options['zIndex']) {
      dropback.style.zIndex = options['zIndex'] - 1;
    } else {
      dropback.style.zIndex = defaultOptions['zIndex'] - 1;
    }
    dropback.style.background = '#000';
    dropback.style.opacity = '0.5';
    return dropback;
  }

  function createLoading() {
    let loading = document.createElement('div');
    loading.classList.add('loading', 'large', 'fixed-position');
    loading.id = 'iframe-loading'
    return loading;
  }

  function findModal(iframe, dropback, loading) {
    if(window.addEventListener) {
      window.addEventListener('message', event => {
        iframeResponse(event, iframe, dropback, loading)
      }, false);
    } else if(window.attachEvent) {
      window.attachEvent('onmessage', event => {
        iframeResponse(event, iframe, dropback, loading)
      }, false);
    } else {
      window['onmessage'] = (event) => {
        iframeResponse(event, iframe, dropback, loading)
      }
    }
  }

  function iframeResponse(event, iframe, dropback, loading) {
    if(event.data.iframe) {
      event.target.document.body.style.overflow = 'hidden';
    }
    if(event.data.iframe === 'finish') {
      if(window.innerWidth > 768) {
        iframe.style.transform =  "translate(-50%, -5%)";
        iframe.style.webkitTransform =  "translate(-50%, -50%)";
      } else {
        iframe.style.right =  "0";
      }
      event.target.document.body.style.overflow = 'auto';
      if(loading.parentNode) {
        loading.parentNode.removeChild(loading);
      } else {
        loading.remove();
      }
    }
    if(event.data.iframe === 'load-checkout') {
      if(loadingIcon) {
        document.body.appendChild(loadingIcon);
      }
    }
    if(event.data.iframe === 'finish-checkout') {
      if(loadingIcon) {
        if(loadingIcon.parentNode) {
          loadingIcon.parentNode.removeChild(loadingIcon);
        } else {
          loadingIcon.remove();
        }
      }
    }
    if(event.data.iframe === 'close') {
      if(window.innerWidth > 768) {
        iframe.style.transform =  "translate(-50%, -75%)";
        iframe.style.webkitTransform =  "translate(-50%, -75%)";
      } else {
        iframe.style.right = '-100%';
      }
      setTimeout( () => {
        closeWindow(iframe);
        if(dropback.parentNode) {
          dropback.parentNode.removeChild(dropback);
        } else {
          dropback.remove();
        }
      }, 300)
      event.target.document.body.style.overflow = 'auto';
    }
  }


  function closeWindow(e)  {
    if(e.parentNode) {
      e.parentNode.removeChild(e)
    } else {
      e.remove();
    }
  }

}
window.wtmEmbedJS = wtm_embed;
